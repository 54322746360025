<template>
    <div>
      <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0 bg-blue">
          <b-navbar-toggle target="nav_collapse_actions"></b-navbar-toggle>
          <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Avance de Nivel</h1>
          <b-collapse is-nav id="nav_collapse_actions" >
            <b-navbar-nav class="ml-auto">
              <date-picker  class="date-picker" v-model="intervalo" lang="es" type="date" 
              :time-picker-options="timePickerOptions" range confirm :clearable="true"></date-picker>
            </b-navbar-nav>
            <b-navbar-nav  class="ml-auto">
              <b-nav-item  href="#" @click="generarReporte">Generar</b-nav-item>
              <download-excel :disabled="!registros || registros && !registros.length"
                  class="nav-link"
                  :data="registros"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="reporte.xls">
                  Descargar
                </download-excel>
            </b-navbar-nav>
          </b-collapse>
      </b-navbar>
  
        <div class="container-fluid" >
        <div class="reporte_dia table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Nombres del estudiante</th>
                <th scope="col">Apellidos del estudiante</th>
                <th scope="col">Tipo de documento</th>
                <th scope="col">Número de documento</th>
                <th scope="col">Número de celular</th>
                <th scope="col">Correo electrónico</th>
                <th scope="col">Estado civil</th>
                <th scope="col">Fecha de nacimiento</th>
                <th scope="col">Lugar de origen</th>
                <th scope="col">Estrato</th>
                <th scope="col">Régimen de salud</th>
                <th scope="col">Nivel de formación</th>
                <th scope="col">Ocupación</th>
                <th scope="col">Discapacidad</th>
                <th scope="col">Grupo</th>
                <th scope="col">Fecha inicio de nivel</th>
                <th scope="col">Fecha avance de nivel</th>
                <th scope="col">Sede avance</th>
                <th scope="col">Sede original </th>
                <th scope="col">Género</th>
                <th scope="col">Idioma</th>
                <th scope="col">Nivel actual</th>
                <th scope="col">Resultado</th>
                <th scope="col">Nota IFE</th>
                <th scope="col">Nota final módulo</th>
                <th scope="col">Módulos adquiridos</th>
                <th scope="col">Folio</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in registros">
                <td>{{item.estudiante.primer_nombre}} {{item.estudiante.segundo_nombre}}</td>
                <td>{{item.estudiante.primer_apellido}} {{item.estudiante.segundo_apellido}}</td>
                <td>{{item.estudiante.tipo_documento}}</td>
                <td>{{item.estudiante.numero_documento}}</td>
                <td>{{item.estudiante.movil}}</td>
                <td>{{item.estudiante.email}}</td>
                <td>{{item.estudiante.estadoCivil ? item.estudiante.estadoCivil.descripcion : null}}</td>
                <td>{{presentDate(item.estudiante.fecha_nacimiento)}}</td>
                <td>{{item.estudiante.lugar_origen}}</td>
                <td>{{item.estudiante.estrato}}</td>
                <td>{{item.estudiante.regimenSalud ? item.estudiante.regimenSalud.nombre : null}}</td>
                <td>{{item.estudiante.nivelEducativo ? item.estudiante.nivelEducativo.descripcion : ''}}</td>
                <td>{{item.estudiante.ocupacion ? item.estudiante.ocupacion.descripcion : null}}</td>
                <td>{{item.estudiante.discapacidad ? item.estudiante.discapacidad.descripcion : null}}</td>
                <td>{{item.estudiante.grupoEtnico ? item.estudiante.grupoEtnico.descripcion : null}}</td>
                <td>{{presentDate(item.updated_at)}}</td><!-- GUARDAR DE AVANCE-->
                <td>{{presentDate(item.comienza_nivel_en)}}</td><!-- GUARDAR DE INICIO-->
                <td>{{item.estudiante.sede_original.nombre}}</td><!-- GUARDAR SEDE EN QUE SE GENERA AVANCE-->
                <td>{{item.sede ? item.sede.nombre : (item.estudiante.sede_original.nombre)}}</td><!-- GUARDAR SEDE EN QUE SE GENERA AVANCE-->
                <td>{{item.estudiante.genero}}</td>
                <td>{{getIdioma(item)}}</td>
                <td>{{item.cursoAnterior ? item.cursoAnterior.nombre : ''}}</td>
                <td>{{item.resultado}}</td>
                <td>{{item.nota_ife}}</td>
                <td>{{item.nota}}</td>
                <td>{{item.estudiante.modulos_adquiridos}}</td>
                <td>{{item.folio}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
        
    </div>
  </template>
  
  <script>
    import {mapState, mapActions, mapMutations} from 'vuex';
    import Vue from 'vue';
    import DatePicker from 'vue2-datepicker'
  
    export default {
      name: 'ReporteSIET',
      components: {
        DatePicker
      },
      data () {
        return {
            intervalo:[],
            registros: [],
            timePickerOptions:{    
            },
            json_fields: {
              "Nombres del estudiante": {
                callback: (value) => {
                  return `${value.estudiante.primer_nombre} ${value.estudiante.segundo_nombre}`;
                }
              },
              "Apellidos del estudiante": {
                callback: (value) => {
                  return `${value.estudiante.primer_apellido} ${value.estudiante.segundo_apellido}`;
                }
              },
              "Tipo de documento":"estudiante.tipo_documento",
              "Número de documento":"estudiante.numero_documento",
              "Número de celular":"estudiante.movil",
              "Correo electrónico":"estudiante.email",
              "Estado civil":"estudiante.estadoCivil.descripcion",
              "Fecha de nacimiento": {
                field: "estudiante.fecha_nacimiento",
                callback: (value) => {
                  return this.presentDate(value);
                },
              },
              "Lugar de origen":"estudiante.lugar_origen",
              "Estrato":"estudiante.estrato",
              "Régimen de salud":"estudiante.regimenSalud.nombre",
              "Nivel de formación":"estudiante.nivelEducativo.descripcion",
              "Ocupación":"estudiante.ocupacion.descripcion",
              "Discapacidad":"estudiante.discapacidad.descripcion",
              "Grupo":"estudiante.grupoEtnico.descripcion",
              "Fecha inicio de nivel":{
                field: "comienza_nivel_en",
                callback: (value) => {
                  return this.presentDate(value);
                },
              },
              "Fecha avance de nivel":{
                field: "updated_at",
                callback: (value) => {
                  return this.presentDate(value);
                },
              },
              "Sede avance":"sede.nombre",
              "Sede original":"estudiante.sede_original.nombre",
              "Género":"estudiante.genero",
              "Idioma":{
                callback: (value) => {
                  return this.getIdioma(value);
                }
              },
              "Nivel actual":"cursoAnterior.nombre",
              "Resultado":"resultado",
              "Nota IFE":"nota_ife",
              "Nota final módulo":"nota",
              "Módulos adquiridos":"estudiante.modulos_adquiridos",
              "Folio":"folio"
            }
        }
      },
      mounted () {
        
      },
      methods:{
        ...mapActions({
        }),
        ...mapMutations({
          startProcessing: 'startProcessing',
          stopProcessing: 'stopProcessing'
        }),
        
        generarReporte(){
          console.log('Generando reporte....')
          this.startProcessing()
          Vue.http.post('reportes/siet',{
            fecha_inicio:this.$moment(this.intervalo[0]).format('YYYY-MM-DD'),
            fecha_fin:this.$moment(this.intervalo[1]).format('YYYY-MM-DD')
          }).then(
            response =>{
              this.registros = response.data;
            }
          ).catch(error=>{
            console.log(error)
          }).finally(()=>{
            this.stopProcessing()
          })
        },
        presentDate(value){
          if (value) {
            return this.$moment(value).format('DD/MM/YYYY')
          }
          return '';
        },
        getIdioma(item) {
          if (item.cursoAnterior) {
            return item.cursoAnterior ? (item.cursoAnterior.codigo.indexOf('EN') > -1 ? 'Inglés' : 'Frances') : '';
          } else if (item.cursoNuevo) {
            return item.cursoNuevo ? (item.cursoNuevo.codigo.indexOf('EN') > -1 ? 'Inglés' : 'Frances') : '';
          }
          return '';
        },
      },
      computed: {
        ...mapState({
         
        }),      
      }
    }
  </script>
  
  <style scope>
  
  .date-picker{
    
  }
  </style>