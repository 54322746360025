<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_docentes"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Novedades</h1>
        <b-collapse is-nav id="nav_collapse_docentes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item  href="#" @click="procesarCancelar">Volver</b-nav-item>
            <b-nav-item  href="#" v-if="docente && permiso('3329CE3B')" @click="creando=true">Crear Novedad</b-nav-item>
            <b-nav-item  href="#" @click="actualizar">Actualizar</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
      <div v-if="docente">
        <h5 class="mb-3">Lista de Novedades para {{nombreCompleto}}</h5>

        <b-row>
          <b-col cols="2" class="celda-head text-center" >Fecha</b-col>
          <b-col cols="2" class="celda-head text-center" >Hora</b-col>
          <b-col cols="" class="celda-head text-center" >Motivo</b-col>
          <b-col cols="2" class="celda-head text-center" >Creado</b-col>
        </b-row>
        <b-row class="fila" v-for="(novedad, index) in novedades" :key="index">
          <b-col cols="2" >{{novedad.fecha}}</b-col>
          <b-col cols="2" >{{novedad.hora}}</b-col>
          <b-col cols="" >{{novedad.observaciones}}</b-col>
          <b-col cols="2" >{{novedad.created_at}}</b-col>
        </b-row>
      </div>
      <hr>
    </div>
    <b-modal @ok="procesarCrear"  @cancel="reiniciarCandidato" v-model="creando"  id="modalCrearNovedad"
    :ok-disabled="!puedeProcesarCrear"  title="Nueva Novedad">

      <div class="mb-2">
        <h5 class="">Fecha:</h5>
        <date-picker :not-before="fechaActual" class="date-picker" v-model="candidato.fecha" lang="es" type="date" 
      :time-picker-options="timePickerOptions" :clearable="true"></date-picker>
      </div>

      <div class="mb-2" v-if="candidato.fecha">
        <h5 class="">Hora:</h5>
        <b-form-select v-model="candidato.horas" :options="posiblesHoras" multiple :select-size="10"></b-form-select>
      </div>

      <div class="mb-2" v-if="candidato.fecha && candidato.horas">
        <h5 class="my-1">Motivo:</h5>

        <b-form-select  class="mb-1" v-model="motivoSeleccionado" :options="posiblesMotivos"></b-form-select>
        <div >
          <b-form-textarea
            id="textarea"
           
            v-model="candidato.motivo"
            placeholder="Escriba el motivo de la novedad."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
          <pre class="d-block text-right">{{ candidato.motivo.length }}/{{ motivoMin }}</pre>
        </div>
      </div>

    </b-modal>
    <div v-if="procesando">
      <BlockUI message="procesando..."></BlockUI>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  import DatePicker from 'vue2-datepicker'
  import utils from '@/modules/utils';

  export default {
    name: 'DocenteNovedades',
    components: {
      DatePicker
    },
    data () {
      return {
        timePickerOptions:{},
        docente_id: null,
        novedades:[],
        creando: false,
        procesando: false,
        motivoMin: 30,
        motivoSeleccionado: null,
        candidato : {
          docente_id:null,
          fecha:null,
          horas:[],
          motivo:''
        }
      }
    },
    mounted () {
     this.docente_id = this.$route.params.id;
     if(!this.detalle[this.docente_id]){
        this.actualizarDocente()
      }
      if(this.novedades.length == 0 ){
        this.actualizar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'docentes/fetchDetalle',
        editar: 'docentes/editar',
        setWarning: 'setWarning',
        setDisponibilidad: 'docentes/setDisponibilidad',
        setCursosPermitidos: 'docentes/setCursosPermitidos',
        setTiposPermitidos: 'docentes/setTiposPermitidos',
        setSalonPreterminado: 'docentes/setSalonPreterminado',
        fetchLista: 'docentes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      procesarCancelar(){
        this.fetchDetalle(this.docente_id).then(()=>{
          this.$router.push('/docente/detalle/'+this.docente_id)
        })
      },
      procesarCrear(){
        this.procesando = true
        this.candidato.docente_id = this.docente_id
        if(this.motivoSeleccionado==null){
          console.log('Debe seleccionarse un motivo')
          return
        }
        //if(this.motivoSeleccionado!=10){
          let motivo = this.posiblesMotivos.find(element=>{
            return element.value == this.motivoSeleccionado
          })
          this.candidato.motivo = motivo.text+' / '+this.candidato.motivo
        //}

          Vue.http.post('novedad_docente/crear',this.candidato).then()
          .catch(error=>{
            this.setWarning(error.data)
            console.log(error)
          }).finally(()=>{
            this.procesando = false
            this.actualizar()
            this.reiniciarCandidato()
          })
          
      },
      actualizarDocente(){
        this.fetchDetalle(this.docente_id)
      },
      actualizar(){
        this.procesando = true
        Vue.http.post('novedad_docente/ver',{docente_id:this.docente_id}).then(
          response =>{
            this.novedades = response.data
          }
        )
        .catch(error=>{
          console.log(error)
        }).finally(()=>{
          this.procesando = false
        })

      },
      reiniciarCandidato(){
        this.candidato = {
          docente_id:this.docente_id,
          fecha:null,
          horas:[],
          motivo:''
        }
        this.motivoSeleccionado = null
      },

    },
    computed:{
      ...mapState({
        detalle: state => state.docentes.detalle, 
        warning: state => state.warning,
        disponibilidad_general: state => state.asw.disponibilidad_general, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      docente(){
        return this.detalle[this.docente_id]
      },
      puedeProcesarCrear(){
        if(this.candidato && this.candidato.fecha && this.candidato.horas.length>0 
          && this.motivoSeleccionado && 
          //(this.motivoSeleccionado!=10 || this.candidato.motivo.length >= this.motivoMin)
          this.candidato.motivo.length >= this.motivoMin
          ){
          return true
        }
        return false
      },
      nombreCompleto(){
        let texto = this.docente.primer_nombre
        texto += this.docente.segundo_nombre?(' '+this.docente.segundo_nombre):''
        texto += this.docente.primer_apellido?(' '+this.docente.primer_apellido):''
        texto += this.docente.segundo_apellido?(' '+this.docente.segundo_apellido):''
        return texto
      },
      posiblesHoras(){
        if(this.candidato.fecha){
          let fecha = new Date(this.candidato.fecha)
          let dia = fecha.getDay()
          if([1,2,3,4,5,6].includes(dia)){
            let diaObj = this.disponibilidad_general.dias[dia]
            return diaObj.horas.map(element=>{
              return element.hora
            })
          }
        }

        return []
      },

      posiblesMotivos(){
        let lista = [
          {value:1,text:'LR- Licencia Remunerada'},
          {value:2,text:'LNR- Licencia No Remunerada'},
          {value:3,text:'INCAP- Incapacidad'},
          {value:4,text:'RTDO- Llegada tarde'},
          {value:5,text:'VCS- Vacaciones'},
          {value:10,text:'Otro'},
        ]
        return lista

      },
      fechaActual(){
        let fecha = utils.getManana()
        console.log(fecha)
        return utils.getManana()
        
      }

      
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>