
<template>
  <div class="container mt-3">
    
      <h2>Sedes Permitidas</h2>
      <div class="mb-3 row" v-if="listaSedes.length>0">
        <div class="" v-for="sede in listaSedes">
          <button class="pt-0 pb-0 mt-1 mr-1 btn btn-success" v-if="sedes.includes(sede.id)"
          @click="procesarQuitarSede(sede.id)"
          title="Click para quitar">
            {{ sede.nombre }}  
          </button>
          <button class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" v-else
          @click="procesarAgregarSede(sede.id)"
          title="Click para agregar">
            {{ sede.nombre }}  
          </button>
        </div>
      </div>
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';

  export default {
    name: 'FuncionarioEditarSede',
    data () {
      return {
        sedes : [],
        editando : false
      }
    },
    props: {
      funcionario: Object
    },
    mounted () {
      if(this.listaSedes.length==0){
      	this.fetchSedes()
      }
      if(this.funcionario && this.funcionario.sedes_permitidas){
      	this.sedes = this.funcionario.sedes_permitidas	
      }
      
    },
    methods:{
      ...mapActions({
        fetchDetalle: 'funcionarios/fetchDetalle',
        fetchSedes: 'sedes/fetchLista',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        startProcessing: 'startProcessing',
        stopProcessing: 'stopProcessing'
      }),

      procesarAgregarSede(value){
      	this.startProcessing()
        Vue.http.post('funcionario/agregar_sede',{id:this.funcionario.id,sede_id:value})
          .then(response=>{

          })
          .catch(error=>{
            this.setWarning(error)
            console.log(error)
          }).finally(()=>{
          	this.stopProcessing()
            this.fetchDetalle(this.funcionario.id).then(funcionario=>{
            	this.sedes = funcionario.sedes_permitidas
            })
          })
      },
      procesarQuitarSede(value){
      	this.startProcessing()
        Vue.http.post('funcionario/quitar_sede',{id:this.funcionario.id,sede_id:value})
          .then()
          .catch(error=>{
            this.setWarning(error)
            console.log(error)
          }).finally(()=>{
          	this.stopProcessing()
            this.fetchDetalle(this.funcionario.id).then(funcionario=>{
            	this.sedes = funcionario.sedes_permitidas
            })
          })
      },


      iniciarEdicion(){
        this.editando=true
      }
      
    },
    computed:{
      ...mapState({
        listaSedes: state => state.sedes.lista, 
      }),
      ...mapGetters({
        
      }),
      procesable: {
        get () {
          return true
        },
        set(value){}
      },
     
      
    }
  }
</script>

<style scope>
 
</style>

