<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_estudiantes"></b-navbar-toggle>
        <h1 v-if="estudiante" class="text-center navbar-brand pt-0 pb-0 mb-0">{{ estudiante.primer_nombre }} {{ estudiante.primer_apellido }}</h1>
        <b-collapse is-nav id="nav_collapse_estudiantes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <a :href="`#/estudiantes`" class="nav-link" title="Lista">Lista</a>
            <a v-if="estudiante && permiso('4EBC511D')" :href="`#/estudiante/progreso/${ estudiante.id }`" class="nav-link" title="Ver">Registro Académico</a>
            <a v-if="estudiante" :href="`#/estudiante/editar/${ estudiante.id }`" class="nav-link" title="Ver">Editar</a>
            <a v-if="estudiante && permiso('31BEFF98')" :href="`#/estudiante/cambiarpassword/${ estudiante.id }`" class="nav-link" title="Ver">Password</a>
            <b-nav-item  href="#" @click="actualizar">Actualizar</b-nav-item>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
      
      <div v-if="estudiante">
        <h2>Datos básicos</h2>
        
        <div class="row">
          <div class="col-3"><strong>Tipo Documento:</strong>
          </div><div class="col-9">{{ estudiante.tipo_documento }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Documento:</strong>
          </div><div class="col-9">{{ estudiante.numero_documento }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>eMail:</strong></div>
          <div class="col-9">{{ estudiante.email }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Telefono:</strong></div>
          <div class="col-9">{{ estudiante.telefono }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Celular:</strong></div>
          <div class="col-9">{{ estudiante.movil }}</div>
        </div>
        <hr>
        <h2>Avanzado</h2>
        <!--
        <div v-if="estudiante.inscripciones">
          <div class="row" v-for="inscripcion in estudiante.inscripciones">
            <div class="col-3"><strong>{{ inscripcion.curso.nombre }}</strong></div>
            <div class="col-9">{{ fechaFormated(inscripcion.fecha_inicio) }}-{{ fechaFormated(inscripcion.fecha_caducidad) }}</div>
          </div>
        </div>
        <div v-else>
          El estudiante no tiene cursos inscritos
        </div>
        -->
        <div  class="row">
          <div class="col-3"><strong>Sede Original:</strong></div>
          <div class="col-9" v-if="estudiante.sede_original">{{ estudiante.sede_original.nombre }}</div>
          <div class="col-9" v-else>El estudiante no tiene sede asignada</div>
        </div>
        
        <div  class="row">
          <div class="col-3"><strong>Nivel Actual:</strong></div>
          <div class="col-9" v-if="estudiante.nivel_actual">{{ estudiante.nivel_actual.descripcion }}</div>
          <div class="col-9" v-else>El estudiante no tiene un nivel activo</div>
        </div>

        <div  class="row">
          <div class="col-3"><strong>Curso Actual:</strong></div>
          <div class="col-9" v-if="estudiante.curso_actual">{{ estudiante.curso_actual.nombre }}</div>
          <div class="col-9" v-else>El estudiante no tiene un curso activo</div>
        </div>

        <div  class="row">
          <div class="col-3"><strong>Vigencia:</strong></div>
          <div class="col-9" v-if="estudiante.vigencia">{{ fechaFormated(estudiante.vigencia) }}</div>
          <div class="col-9" v-else>El estudiante no tiene configurada vigencia</div>
        </div>

        <div  class="row">
          <div class="col-3"><strong>Método de pago:</strong></div>
          <div class="col-9" v-if="estudiante.tipo_pago">{{ estudiante.tipo_pago }}</div>
          <div class="col-9" v-else>El estudiante no tiene método de pago.</div>
        </div>

        <div  class="row">
          <div class="col-3"><strong>Sábados:</strong></div>
          <div class="col-9" v-if="estudiante.sabados">Incluye sábados</div>
          <div class="col-9" v-else>No Incluye sábados</div>
        </div>

        <div  class="row">
          <div class="col-3"><strong>Versant:</strong></div>
          <div class="col-9" v-if="estudiante.versant">Es versant</div>
          <div class="col-9" v-else>No es versant</div>
        </div>

        <div  class="row">
          <div class="col-3"><strong>Require clase 0:</strong></div>
          <div class="col-9" v-if="estudiante.requiere_clase_0">Requiere</div>
          <div class="col-9" v-else>No requiere</div>
        </div>

        <div  class="row">
          <div class="col-3"><strong>Términos y condiciones:</strong></div>
          <div class="col-9" v-if="estudiante.acepta_tyc">{{estudiante.acepta_tyc}}</div>
          <div class="col-9" v-else>Aún no ha aceptado</div>
        </div>
        
        <hr>
        <h2>Agendamientos</h2>
        <programaciones :estudiante="estudiante"></programaciones>
        <!-- POR AHORA NO VAMOS A AGENDAR DESDE AQUÍ
        <div v-if="agendando">
          <programar :estudiante="estudiante" v-on:update-detalle="actualizar"></programar>
          <b-button size="sm" variant="danger" @click="agendando=false">Cancelar</b-button>
        </div>
        <div v-else>
          <b-button size="sm" variant="primary" @click="agendando=true">Agendar</b-button>
        </div>
      -->
      <!-- //APLAZADO
        <hr>
        <h2>Observaciones</h2>
        <DetalleObservaciones :estudiante="estudiante"></DetalleObservaciones>
      -->
      </div>


      <div v-else>
        No hay información
      </div>

    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Programaciones from '@/components/Estudiantes/DetalleProgramaciones';
  import Programar from '@/components/Estudiantes/DetalleProgramar';
  import DetalleObservaciones from '@/components/Estudiantes/DetalleObservaciones';

  export default {
    name: 'DetalleEstudiante',
    components: {
      Programaciones,
      Programar,
      DetalleObservaciones,
    },
    data () {
      return {
          agendando: false,
          estudiante_id: null,
      }
    },
    beforeCreate() {
      this.$store.commit('estudiantes/incializarDetalle',this.$route.params.id)
    },
    
    mounted () {
      this.estudiante_id = this.$route.params.id;    
      console.log('Detalle mounted actualizar')
      this.actualizar()
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'estudiantes/fetchDetalle',
        fetchAgendamientos: 'estudiantes/fetchAgendamientos',
      }),
      ...mapMutations({
        setDetalle: 'estudiantes/setDetalle',
        incializarDetalle: 'estudiantes/incializarDetalle',
      }),
      actualizar(){
        this.fetchDetalle(this.estudiante_id).then(()=>{
          this.fetchAgendamientos(this.estudiante_id)
        })
      },
      fechaFormated(value) {
        return value.split('T')[0]
      }   
    },
    computed:{
      ...mapState({
        detalle: state => state.estudiantes.detalle, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      estudiante(){
        return this.detalle[this.estudiante_id]
      }, 
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>