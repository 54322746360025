<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
        <b-navbar-toggle target="nav_collapse_actions"></b-navbar-toggle>
        <!--
        <span style="font-size: 12px" class="text-center navbar-brand pt-0 pb-0 mb-0">{{ titulo }}</span>
        -->
        <!-- Right aligned nav items -->
        <b-navbar-nav >
          <b-nav-item-dropdown v-if="agenda_disponible" :text="titulo_dia" >
            <b-dropdown-item v-for="dia in agenda_disponible" :key="dia.timestamp" href="#" 
            @click="cambiarDia(dia)">
            {{ dia.texto }}
          </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav >
          <b-nav-item-dropdown v-if="dia_actual" :text="titulo_hora" >
            <b-dropdown-item v-for="hora in dia_actual.horas" :key="hora.id" href="#" 
            @click="cambiarHora(hora)">
            {{ hora.nombre_corto }}
          </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-collapse is-nav id="nav_collapse_actions" >
          <b-navbar-nav  v-if="dia_actual && hora_actual" class="ml-auto">
            <b-nav-item  v-if="permiso('8A511756')" href="#" @click="reorganizar">R</b-nav-item>
            <b-nav-item  v-if="permiso('5B4F11C4')" href="#" @click="procesarNuevoAgendamiento" >Agendar</b-nav-item>

            <!--<b-nav-item  href="#" @click="generar" >Automatico</b-nav-item>-->
            <!--<b-nav-item  href="#" @click="nueva"><icon  name="plus"></icon></b-nav-item>-->
            
            <b-nav-item  v-if="permiso('BC8C54A9')" href="#" @click="procesarNuevaClase"><icon  name="plus"></icon></b-nav-item>
            <b-nav-item  href="#" @click="recargarClases"><icon  name="sync-alt"></icon></b-nav-item>
<!--
            <b-nav-item  href="#" @click="toogleRightPanel" :active="rightPanel">Solicitudes</b-nav-item>
-->
          </b-navbar-nav>
        </b-collapse>
        
    </b-navbar>
  	<div class="container-fluid principal" >
  		
  				<div v-if="iniciado">
  					<div v-for="(dia, index_1) in agenda_disponible" :key="index_1">
  						<ProgramadorXDiaXHora :activo="tabActivo" :rightPanel="rightPanel"
  						v-for="(hora, index_2) in dia.horas" :key="index_2" :hora="hora" :dia="dia"/>
  					</div>
  				</div>
  			
  	</div>
    <b-modal  no-close-on-esc no-close-on-backdrop hide-header-close v-model="agendando"  hide-footer id="modalprogramador" title="Nuevo Agendamiento">
      <ProgramadorProgramador v-if="dia_actual && hora_actual" :dia="dia_actual" :hora="hora_actual"/>
    </b-modal>

    <b-modal  no-close-on-esc no-close-on-backdrop hide-header-close v-model="creando_clase"  hide-footer id="modalclase" title="Nueva Clase">
      <ProgramadorNuevaClase :clase="nueva_clase" />
    </b-modal>

  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import ProgramadorXDiaXHora from '@/components/Programador/ProgramadorXDiaXHora';
  import ProgramadorProgramador from '@/components/Programador/ProgramadorProgramador';
  import ProgramadorNuevaClase from '@/components/Programador/ProgramadorNuevaClase';
  import Icon from 'vue-awesome/components/Icon'
  import 'vue-awesome/icons/sync-alt'
  import 'vue-awesome/icons/plus'
  import Vue from 'vue';

  export default {
    name: 'Programador',
    components: {
      ProgramadorXDiaXHora,
      ProgramadorProgramador,
      ProgramadorNuevaClase,
      Icon
    },
    data () {
      return {
        rightPanel : true,
    		leftPanel : true,
        dia_actual : null,
        hora_actual : null,
        nuevo_agendamiento : {
          dia: null,
          hora: null,
          tipo_solicitud : null,
          tipo_clase : null
        },
        nueva_clase: {
          dia: null,
          hora: null,
          tipo_clase : null
        },
        agendando : false,
        creando_clase: false
      }
    },
    mounted () {
      if(!this.agenda_disponible){
        this.fetchAgendaDisponible()
      }
      if(!this.cursos){
        this.fetchCursos()
      }
      if(this.profesores.length==0){
        this.fetchProfesores()
      }
      if(this.salones.length==0){
        this.fetchSalones()
      }
    },
    methods:{
      ...mapActions({
        fetchAgendaDisponible: 'asw/fetchAgendaDisponible',
        fetchCursos : 'asw/fetchCursos',
        fetchClases : 'clases/fetchClases',
        fetchClasesXMarca : 'clases/fetchClasesXMarca',
        fetctNovedades: 'docentes/fetchNovedades', 
        fetchProfesores : 'docentes/fetchLista',
        fetchSalones : 'salones/fetchLista',
        fetchAgendamientos : 'agendamientos/fetchAgendamientos',
        fetchAgendamientosXMarca : 'agendamientos/fetchAgendamientosXMarca',
        generarParcial : 'clases/generarParcial'
      }),
      ...mapMutations({
        nuevaClase: 'clases/nuevaClase',
      }),
      toogleRightPanel(){
        this.rightPanel = !this.rightPanel
      },
      edit(){

      },
      procesarNuevoAgendamiento(){
        this.nuevo_agendamiento.dia = this.dia_actual,
        this.nuevo_agendamiento.hora = this.hora_actual,
        this.nuevo_agendamiento.tipo_solicitud = null,
        this.nuevo_agendamiento.tipo_clase = null
        this.agendando = true
      },
      procesarNuevaClase(){
        this.nueva_clase.dia = this.dia_actual,
        this.nueva_clase.hora = this.hora_actual,
        this.nueva_clase.tipo_clase = null
        this.creando_clase = true
      },
      cambiarDia(value){
        this.dia_actual = value
        if(this.hora_actual){
          var found = this.dia_actual.horas.find((element) => {
            return element.hora == this.hora_actual.hora;
          });
          if(found){
            this.hora_actual = found
            this.recargarClases()
          }else{
            this.hora_actual =  null
          }
        }
      },
      cambiarHora(value){
        this.hora_actual=value
        this.recargarClases()
      },
      nueva(){
        let now = new Date()
        let candidato = {
          profesor_id:-1,
          curso_id: -1,
          salon_id: -1,
          fecha:this.hora_actual.marca_tiempo.split('T')[0],
          hora:this.hora_actual.marca_tiempo.split('T')[1],
          tipo_clase:'',
          id_temporal: now.getTime()
        }
        this.nuevaClase({marca:this.hora_actual.marca_tiempo,candidato:candidato})

      },
      generar(){
        //console.log('Generando automaticamente....')

        this.generarParcial({
          marca_tiempo:this.hora_actual.marca_tiempo
        }).then(()=>{

        })
      },
      
      recargarClases(){

        if(this.salones.length == 0){
          this.fetchSalones()
        }
        let filtro = {marcatiempo:this.hora_actual.marca_tiempo};
        this.consultarNovedades(filtro);
        this.fetchClasesXMarca(filtro)
        .then(()=>{
            //OPTIMIZACION this.fetchAgendamientos().then(()=>{}) 
            this.fetchAgendamientosXMarca({marca_tiempo:this.hora_actual.marca_tiempo}).then(()=>{})
        })
      },
      consultarNovedades(filtro) {
        this.fetctNovedades(filtro)
        .then((result)=>{
          console.log(result);
        })
      },
      reorganizar(){
        let payload = {
          fecha:this.dia_actual.fecha,
          hora:this.hora_actual.hora
        }

        Vue.http.post('agendamientos/reorganizar',payload).then(
            response =>{}
          ).catch(response=>{
            console.log(response)
          }).finally(response=>{
            this.recargarClases()
          })

      }
    },
    computed: {
      ...mapState({
        agenda_disponible: state => state.asw.agenda_disponible,
        cursos: state  => state.asw.cursos,
        clases: state => state.clases.clases,
        profesores: state => state.docentes.lista,
        salones: state => state.salones.lista,
        agendamientos: state => state.agendamientos.agendamientos,

      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),

      iniciado(){
    		if(this.agenda_disponible && this.cursos && this.clases && this.profesores && this.salones && this.agendamientos){
    			return true
    		}
    		return false
      },
      titulo_dia(){
        if(this.dia_actual){
          return this.dia_actual.texto
        }else{
          return 'seleccione...'
        }
        
      },
      titulo_hora(){
        if(this.hora_actual){
          return this.hora_actual.nombre_corto
        }else{
          return 'seleccione...'
        }
        
      },
      titulo(){
        if(this.dia_actual &&  this.hora_actual){
          return this.dia_actual.texto+' '+this.hora_actual.nombre_corto
        }else{
          return 'seleccione...'
        }
        
      },
      tabActivo(){
        if(this.dia_actual &&  this.hora_actual){
          return this.dia_actual.fecha+'T'+this.hora_actual.hora
        }else{
          return null
        }
      },
    }
  }
</script>

<style scope>
.izquierda{
	padding-bottom: 30px
}
</style>