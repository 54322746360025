<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_docentes"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Docentes</h1>
        <b-collapse is-nav id="nav_collapse_docentes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <router-link v-if="permiso('98BEF7FF')" class="nav-link" to="/preasignaciones" >Preasignaciones</router-link>
            <router-link v-if="permiso('66581933')" class="nav-link" to="/docente/nuevo" >Nuevo</router-link>
            <router-link v-if="permiso('5A45ACB1')" class="nav-link" to="/docente/masivo" >Masivo</router-link>
            <b-nav-item  href="#" @click="actualizar">Actualizar</b-nav-item>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container docentes_wrapper mt-3">
      <div v-if="lista" class="tabla">
        <v-client-table name="docentesTable" :columns="columns" :data="lista" :options="options">
          <div slot="activo" slot-scope="lista">
            <span v-if="lista.row.activo==1">SI</span>
            <span v-else>NO</span>
          </div>
          <div slot="accion" slot-scope="lista"><a :href="`#/docente/detalle/${ lista.row.id }`" v-if="permiso('42C7BC3F')" class="btn btn-primary btn-sm" title="Ver">Ver</a></div>
        </v-client-table>
      </div>
    </div>
    <DocenteTipos  />
    <TiposConvenciones  />
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import DocenteTipos from '@/components/Docentes/DocenteTipos';
  import TiposConvenciones from '@/components/TiposConvenciones';
  
  export default {
    name: 'DocenteLista',
    components: {
      DocenteTipos,
      TiposConvenciones
    },
    data () {
      return {
        columns: ['primer_nombre','segundo_nombre',  'primer_apellido', 'segundo_apellido', 'numero_documento', 'email', 'activo','accion'],
        options: {
          filterable: true,
          filterByColumn: false,
          sortable:[],
          perPage: 10,
          perPageValues: [5, 10, 15, 20],
          headings: {
            id: 'Id',
            primer_nombre: 'Primer Nombre',
            segundo_nombre: 'Segundo Nombre',
            primer_apellido: 'Primer Apellido',
            segundo_apellido: 'Segundo Apellido',
            numero_documento: 'Documento',
            activo: 'Activo',
            accion: 'Accion'
          }
        }
      }
    },
    mounted () {
      if(this.lista.length==0){
        this.fetchLista()
      }
    },
    methods:{
      ...mapActions({
        fetchLista: 'docentes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      edit(){

      },
      actualizar(){
        this.fetchLista()
      }
    },
    computed: {
      ...mapState({
        lista: state => state.docentes.lista, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
    }
  }
</script>

<style scope>

</style>