<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_cursos"></b-navbar-toggle>
        <h1 v-if="banner" class="text-center navbar-brand pt-0 pb-0 mb-0">Banners Detalle </h1>
        <b-collapse is-nav id="nav_collapse_cursos">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
          
            <a v-if="banner" :href="`#/banner/editar/${ banner.id }`" class="nav-link" title="Ver">Editar</a>
        
            <a :href="`#/banners/lista`" class="nav-link" title="Ver">Volver</a> 
        </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
      
      <div v-if="">
        
     <!--   <div class="row">
          <div class="col-3"><strong>Código:</strong>
          </div><div class="col-9">{{ banner.sede_id }}</div>
        </div> -->
       <div class="row">
          <div class="col-3"><strong>Nombre y Apellido:</strong>
         </div>
          <div class="col-9">{{ banner.user.primer_nombre }} {{ banner.user.primer_apellido }}</div>  
        </div>    

        <div class="row">
          <div class="col-3"><strong>Url:</strong></div>
          <div class="col-9">{{ banner.url }}</div> 
        </div>
        <div class="row" >
          <div class="col-3" ><strong>Fecha Inicio:</strong></div>
          <div class="col-9">{{ banner.fecha_inicio }}</div> 
        </div>
        <div class="row">
          <div class="col-3"><strong>Fecha Fin:</strong></div>
         <div class="col-9">{{ banner.fecha_fin }}</div> 
        </div>
        <div class="row">
          <div class="col-3"><strong>Estado:</strong></div>
          <div class="col-9">{{ banner.activo}}</div>
        </div>
         <div class="row">
          <div class="col-3"><strong>Tipo:</strong></div>
           <div class="col-9">{{ banner.metodosPago}}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Sede:</strong></div>
         <div class="col-9">{{ banner.sedes}}</div> 
        </div>
        <div class="row">
          <div class="col-3"><strong>Curso:</strong></div>
          <div class="col-9">{{ banner.cursos}}</div> 
        </div>
        
      </div>


    </div>

  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
 
  
  export default {
    name: 'BannersDetalle',
    components: {
   
 },
    data () {
      return {       
       banner_id : null,
       fechainiciot: null,
       date: null,
       banner: {}

      }
    }
    ,

    mounted () {
        
        this.banner_id = this.$route.params.id;
        console.log(this.banner_id)

        this.fetchDetalleBanners(this.banner_id).then((data)=>{
          this.banner = data
          this.banner.sedes = data.sedesNombres.join(', ')
          this.banner.cursos = data.cursosNombres.join(', ')
          this.banner.metodosPago = data.segmento.metodoPago.join(', ')
          this.banner.fecha_inicio= data.fecha_inicio.split('T')[0]
           this.banner.fecha_fin= data.fecha_fin.split('T')[0]
        })
        this.formato()

    },
    
    methods:{
      ...mapActions({
        fetchDetalleBanners: 'banner/fetchDetalleBanners',
      }),
      ...mapMutations({
            setDetalle: 'banner/setDetalle',
        
      }),
      formato(){

          this.fecha1 = new Date(this.banner_id.fecha_inicio)
        
      },
      actualizar(){
           this.fetchDetalleBanners(this.banner_id).then(()=>{
             
          
        })
      }


    },

    computed:{
      ...mapState({
         detalleBanner: state => state.banner.detalleBanner, 
      }),
      banner(){

        return this.detalleBanner[this.banner_id]


      },
     
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>
