<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_examenes"></b-navbar-toggle>
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Examenes</h1>
        <b-collapse is-nav id="nav_collapse_examenes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            
            <router-link v-if="permiso('19E70E2C')" class="nav-link" to="/examen/nuevo" >Nuevo</router-link>
            <b-nav-item  href="#" @click="actualizar">Actualizar</b-nav-item>

          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
    <div class="container examenes_wrapper mt-3">
      <div v-if="lista" class="tabla">
        <v-client-table name="examenesTable" :columns="columns" :data="lista" :options="options">
          <div slot="accion" slot-scope="lista"><a :href="`#/examen/editar/${ lista.row.id }`" class="btn btn-secondary btn-sm" title="Ver">Ver</a></div>
        </v-client-table>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'ExamentesLista',
    data () {
      return {
        columns: ['id', 'codigo',  'nombre', 'accion'],
        options: {
          filterable: true,
          filterByColumn: false,
          sortable:[],
          perPage: 10,
          perPageValues: [5, 10, 15, 20],
          headings: {
            id: 'Id',
            codigo: 'Codigo',
            nombre: 'Nombre',
            accion: 'Accion'
          }
        }
      }
    },
    mounted () {
      if(this.lista.length==0){
        this.fetchLista()
      }
    },
    methods:{
      ...mapActions({
        fetchLista: 'examenes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      edit(){

      },
      actualizar(){
        this.fetchLista()
      }
    },
    computed: {
      ...mapState({
        lista: state => state.examenes.lista, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
    }
  }
</script>

<style scope>

</style>
