<template>
  <div>
    <h4 v-if="dia" class="my-1">{{ dia.fecha }} {{ hora.hora }}</h4>
    

    
      
    <div class="form-group">
    	
      <!--
    	  <b-form-select multiple :select-size="4"  v-model="tipos" :options="options" class="select-tipo">
      	</b-form-select>
-->
    
        <div class="m-3 row"><strong>Seleccione el tipo de clase:</strong></div>
        <div class="m-3 row" v-if="posibles_tipos">

          <div class="" v-for="tipo in posibles_tipos">

                <button class="pt-0 pb-0 mt-1 mr-1 btn btn-success" 
                v-if="tipos.includes(tipo.id)"
                @click="quitarTipo(tipo.id)"
                title="Click para quitar">
                  {{ tipo.nombre_largo }}  
                </button>
                <button class="pt-0 pb-0 mt-1 mr-1 btn btn-dark" v-else
                @click="agregarTipo(tipo.id)"
                title="Click para agregar">
                  {{ tipo.nombre_largo }}  
                </button>
            
          </div>
        </div>
        <div>
      <b-alert show variant="danger" v-if="error">{{ error }}</b-alert>
      <b-alert show variant="info" v-if="info">{{ info }}</b-alert>
    </div>
        <div v-if="tipos.length > 0" >
          <div v-if="posiblesDocentes.length > 0" >
            <div class="m-3 row"><strong>Seleccione el docente:</strong></div>
            <b-form-select  v-model="docente_id" :options="posiblesDocentes" class="select-tipo">
            </b-form-select>
          </div>
          <div v-else class="m-3 row">
            <strong>No hay docentes disponibles que tenga permitido estos tipos</strong>
          </div>

          <div v-if="posiblesSalones.length > 0" >
            <div class="m-3 row"><strong>Seleccione el salón:</strong></div>
            <b-form-select  v-model="salon_id" :options="posiblesSalones" class="select-tipo">
            </b-form-select>
          </div>
          <div v-else class="m-3 row">
            <strong>No hay salones disponibles que tenga permitido estos tipos</strong>
          </div>
        </div>
    </div>

    <div class="mb-2" v-if="tipos.length > 0 && docente_id && salon_id" >
      <h4 class="my-1">Motivo:</h4>
      <b-form-textarea
      id="textarea"
      v-model="motivo"
      placeholder="Por qué es necesario hacer esta clase manual?"
      rows="3"
      max-rows="6"
    ></b-form-textarea>
    <pre class="d-block text-right">{{ motivo.length }}/{{ motivoMin }}</pre>
    </div>

    <div class="d-block text-right">
      <b-btn v-if="puedeGuardar" class="mx-1" variant="primary" @click="procesarGuardar">Crear</b-btn>
      <b-btn class="mx-1" variant="danger" @click="cancelar">Cancelar</b-btn>
    </div>
    <div>
      <b-alert show variant="danger" v-if="error">{{ error }}</b-alert>
      <b-alert show variant="info" v-if="info">{{ info }}</b-alert>
    </div>
    <div v-if="procesando">
      <BlockUI message="procesando..."></BlockUI>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  export default {
    name: 'ProgramadorNuevaClase',
    components: {
      
    },
    data () {
      return {
       tipos : [],
       docente_id: null,
       salon_id: null,
       error : null,
       info : null,
       procesando : false,
       motivo: '',
       motivoMin: 40
      }
    },
    props:{
      clase: Object,
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
        crear : 'clases/crear',
      }),
      ...mapMutations({
        
      }),
      setError(text){
        this.error = text
        setTimeout(() => {
          this.error = null
        },3000)
      },
      setInfo(text){
        this.info = text
        setTimeout(() => {
          this.info = null
        },3000)
      },
      agregarTipo(id){

        if(this.tipos.length==0){
          this.tipos.push(id)
          let combiarModulosNuevos = [
            ["regular_1","regular_16"],
            ["regular_2","regular_17"],
            ["regular_3","regular_18"],
            ["regular_4","regular_19"],
            ["regular_5","regular_20"],
            ["regular_6","regular_21"]
          ];
          var found = combiarModulosNuevos.find(pareja => {
            return pareja.includes(id) && pareja.includes(id)
          });
          if (found) {
            if (found[0] == id) {
              this.tipos.push(found[1])
            } else {
              this.tipos.push(found[0])
            }
          }
        }else if(this.tipos.length==1){
          let combinacionesPosibles = [
            ["speakup_avanzado","speakup_basico"],
            ['immersion_avanzado','immersion_basico'],
            ['regular_5','regular_6'],
            ['regular_7','regular_8'],
            ['regular_9','regular_a'],
            ["regular_1","regular_16"],
            ["regular_2","regular_17"],
            ["regular_3","regular_18"],
            ["regular_4","regular_19"],
            ["regular_5","regular_20"],
            ["regular_6","regular_21"]
          ]
          let tiposTemporal = this.tipos.slice(0)
          tiposTemporal.push(id)

          var found = combinacionesPosibles.find(pareja => {
            return pareja.includes(tiposTemporal[0]) && pareja.includes(tiposTemporal[1])
          });

          if(found){
            this.tipos.push(id)
          }else{
            this.setInfo('Esta combinación no es permitida.')
          }
             
        }else{
          this.setInfo('No es posible combinar mas de tres tipos de clase.')
        }
        
      },
      quitarTipo(id){
this.tipos.splice(this.tipos.indexOf(id),1)
      },
      procesarGuardar(){
		console.log('procesarGuardar:')
		this.procesando = true

		let datos = {
			fecha: this.clase.dia.fecha,
			hora: this.clase.hora.hora,
      profesor_id: this.docente_id,
      salon_id: this.salon_id,
			tipos: this.tipos,
      motivo: this.motivo
		}
        this.crear(datos).then((data)=>{
          console.log("Clase guardada")
          this.cambio=false;
          this.editando=false;
          this.cancelar()
        }).catch((error)=>{
          console.log("error procesando guardar clase")
        }).finally(()=>{
            this.procesando = false
          })
      },
      cancelar(){ 
        this.docente_id = null
        this.tipos = []
        this.$root.$emit('bv::hide::modal','modalclase')
        this.motivo = ''
      },

    },
    computed: {
      ...mapState({
        clases: state => state.clases.clases,
        posibles_tipos: state => state.asw.tipos, 
      	docentes: state  => state.docentes.lista
      }),
      ...mapGetters({
        getDocentesDisponibles: 'docentes/getDocentesDisponibles', 
        getDocentesConClase: 'clases/getDocentesConClase', 
        getSalonesDisponibles: 'salones/getSalonesDisponibles', 
      }),
      dia:{
        get(){
          return this.clase.dia
        },
        set(){

        }
      },
      hora:{
        get(){
          return this.clase.hora
        },
        set(){

        }
      },

      puedeGuardar(){
        if(this.tipos.length>0 && this.docente_id && this.salon_id && this.motivo.length>=this.motivoMin){
          return true
        }
        return false
      },
      marcaTiempo(){
        return this.clase.dia.fecha+'T'+this.clase.hora.hora
      },
      marcaRelativa(){
        let date = new Date(this.marcaTiempo)
        let dia = date.getDay()
        return  dia+'T'+this.clase.hora.hora
      },
      posiblesSalones(){
        if(this.tipos.length>0){
          let get_salones_disponibles = this.getSalonesDisponibles()
          let salones_posibles = get_salones_disponibles.filter(salon=>{
            for(let x in this.tipos){
              let tipo = this.tipos[x]
              if(!salon.tipos_permitidos.includes(tipo)){
                return false
              }
            }
            return true
          })

          salones_posibles = salones_posibles.filter((salon)=>{
              let hay_clase = this.clases[this.marcaTiempo].find((clase)=>{
                return clase.salon_id == salon.id  
              })
              return !hay_clase
            })

          salones_posibles = salones_posibles.map((salon)=>{
            return {
              value: salon.id,
              text: salon.nombre
            }
          })

          if(salones_posibles.length>0){
            return salones_posibles  
          }
        
        }
        this.salon_id = null
        return []
        
      },
      posiblesDocentes(){

        let posibles_docentes = this.docentesDisponiblesSinClase
        
        if(this.tipos.length > 0 && posibles_docentes.length>0){
          posibles_docentes = posibles_docentes.filter((docente)=>{
            for(let i in this.tipos){
              let tipo = this.tipos[i]
              if(!docente.tipos_permitidos.includes(tipo)){
                return false
              }
            }
            return true
          })
          
                
          posibles_docentes = posibles_docentes.map((docente)=>{
            return {
              value: docente.id,
              text: docente.primer_nombre+' '+docente.primer_apellido
            }
          })
          if(posibles_docentes.length>0){
            return posibles_docentes  
          }
          
        }

        this.docente_id = null
        return []

      },
      docentesDisponiblesSinClase(){
        //Docentes activos que tienen la disponibilidad de horario
        let docentes_disponible = this.getDocentesDisponibles(this.marcaRelativa)
        if(docentes_disponible && docentes_disponible.length>0){
          //Docentes que ya tienen clase en la marca de tiempo
          let docentes_con_clase = this.getDocentesConClase(this.marcaTiempo)
          //Cruzamos los dos para encontrar los que pueden agendar

          let respuesta = docentes_disponible.filter((docente)=>{
            return !docentes_con_clase.includes(docente.id)
          })
          return respuesta
        }
        return []
      },
      options(){
        let mapped = this.posibles_tipos.map((element)=>{
          return {
            value: element.id,
            text: element.nombre_largo
          }
        })
        return mapped;
      }
      
    }
  }
</script>

<style scope>

</style>