<template>
  <div class="container  mt-3">
    <div class="m-auto">
    	
      <div class="form-horizontal">
        
          <div class="form-group row">
            <label class="col-3 col-form-label">Libro</label>
            <div class="col-3">
              <select v-model="curso_id" class="form-control">
                <option disabled value="">Please select one</option>
                <option v-if="opciones" v-for="opcion in lista" :value="opcion.id">{{ opcion.nombre }}</option>
              </select>
            </div>
            <div class="col-3">
              <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
                Activar Curso
              </button> 
            </div>  
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

  export default {
    name: 'DetalleCursoActual',
    components: {
      
    },
    data () {
      return {
        curso_id:null,
      }
    },
    props: {
      estudiante: Object
    },
    mounted () {
      this.fetchCursos({nivel_id:this.estudiante.nivel_id})
      if(this.estudiante){
        this.curso_id = this.estudiante.curso_actual_id  
      }else{
        console.log('Hay que asegurarse de tener un estudiante antes de utilizar el componente DetalleCursoActual')
      }
    },
    methods:{
      ...mapActions({
        activarCurso: 'estudiantes/activarCurso',
        fetchCursos: 'niveles/fetchCursos',
        fetchDetalle: 'estudiantes/fetchDetalle',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      procesar(){
        
        this.activarCurso({
          id:this.estudiante.id,
          curso_id:this.curso_id,
        }).then((apiResponse)=>{
          
          this.limpiar()
          this.setWarning('Curso Activo', { root: true }).then(()=>{
            this.fetchDetalle(this.estudiante.id).then(()=>{
              this.$router.push('/estudiante/detalle/'+this.estudiante.id)
            }) 
          })
          
          
        })
      },
      limpiar(){
        this.curso_id = null
      }
    },
    computed:{
      ...mapState({
        opciones: state  => state.niveles.cursos,
      }),
      ...mapGetters({
        
      }),
      procesable: {
        get () {
          return (this.curso_id && this.curso_id != this.estudiante.curso_actual_id)
        },
        set(value){}
      },
      lista() {
        if (this.opciones) {
          return this.opciones;
        }
        return [];
      },
    }
  }
</script>

<style scope>
  .programar_wrapper .form-container{
      
  }
	.programar_wrapper{
    
	}
</style>

