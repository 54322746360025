<template>
<div class="progreso">
    <b-row>
        <b-col cols="12" v-if="!vista_ligera">
            <p class="m-1" v-for="observacion in observaciones">
            {{ observacion.texto}}<span>  {{ observacion.usuario}} / {{ observacion.fecha}} </span>
            </p>

            <b-button  @click="vista_ligera=true" size="sm" variant="link" >Ver menos</b-button>
            <b-button class="m-0 p-0" @click="cargarObservaciones()" size="sm" variant="link" >Ver histórico</b-button>
        </b-col>
        
        <b-col cols="12" v-if="vista_ligera">
            <b-button class="m-0 p-0" @click="vista_ligera=false" size="sm" variant="link" >Ver más</b-button>
        </b-col>
    </b-row>
</div>
</template>
<style>
.viewIcon{
    padding: 0px 61px 0px;
    font-size:22px !important;
}
.viewNum{
    padding: 0px 61px 0px;
    font-size:18px !important;
}
</style>
<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/check';
import Vue from 'vue';
export default {
    name: 'ProgresoObservaciones',
    components: {
    Icon
    },
    data () {
    return {
        vista_ligera : true,
        observaciones : null,
    }
    },
    props: {
        curso_id: Number,
        estudiante_id: String
    },
    mounted () {
    
    },
    methods:{
    ...mapActions({
        
    }),
    ...mapMutations({
    }),
    cargarObservaciones(){
        let payload = {
            estudiante_id : this.estudiante_id,
            curso_id : this.curso_id
        }
        Vue.http.post('progreso/ver_observaciones_curso',payload).then(
            response =>{
            this.observaciones = response.data
            }
        ).catch(response=>{
            
        }).finally(()=>{

        })
    }

    },
    computed: {
        ...mapState({
        }),
        ...mapGetters({
            permiso: 'auth/permiso', 
        }),
    }
}
</script>

<style scope>
.progreso {
    border-top: 1px solid #bbb;
    font-size:14px;
    line-height: 14px;
    padding-top: 10px;
}
.progreso  strong{}
.progreso  span{
    font-size: 10px;
    line-height: 10px;
    color:#888;
    font-style: oblique;
}
.progreso .btn{
    margin-top:5px;
    margin-bottom:5px;
}
.progreso_agendamientos{}

.progreso_agendamiento{
    padding: 5px;
    margin-top: 5px;
    border-top: 1px solid #ddd;
}
</style>