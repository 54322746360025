import { render, staticRenderFns } from "./DetalleNivelActual.vue?vue&type=template&id=690a9fa3&"
import script from "./DetalleNivelActual.vue?vue&type=script&lang=js&"
export * from "./DetalleNivelActual.vue?vue&type=script&lang=js&"
import style0 from "./DetalleNivelActual.vue?vue&type=style&index=0&id=690a9fa3&prod&scope=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports