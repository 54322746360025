<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_estudiantes"></b-navbar-toggle>
        <h1  class="text-center navbar-brand pt-0 pb-0 mb-0">Editar Estudiante</h1>
        <b-collapse is-nav id="nav_collapse_estudiantes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item  href="#" @click="procesarCancelar">Cancelar</b-nav-item>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
      <div v-if="permiso('9022BCBD')" >
       <h2>Datos básicos</h2>
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div v-if="estudiante">
          <InputTextSimple titulo="Primer Nombre" :modelo="estudiante.primer_nombre" 
            @change="estudiante.primer_nombre = $event"/>
          <InputTextSimple titulo="Segundo Nombre" :modelo="estudiante.segundo_nombre" 
            @change="estudiante.segundo_nombre = $event"/>
          <InputTextSimple titulo="Primer Apellido" :modelo="estudiante.primer_apellido" 
            @change="estudiante.primer_apellido = $event"/>
          <InputTextSimple titulo="Segundo Apellido" :modelo="estudiante.segundo_apellido" 
            @change="estudiante.segundo_apellido = $event"/>
          <SelectTipoDocumento titulo="Tipo de Documento" :modelo="estudiante.tipo_documento" 
            @change="estudiante.tipo_documento=$event"/>
          <InputNumeroDocumento titulo="Número de Documento" :modelo="estudiante.numero_documento" 
            @change="estudiante.numero_documento=$event"/>
          <InputEmailSimple titulo="Email" :modelo="estudiante.email" 
            @change="estudiante.email=$event"/>
          <InputMovil titulo="Telefono" :modelo="estudiante.telefono" 
            @change="estudiante.telefono=$event"/>
          <InputMovil titulo="Movil" :modelo="estudiante.movil" 
            @change="estudiante.movil=$event"/>

        </div>
        <button class="btn btn-block btn-danger" type="submit">Guardar</button>
      </form>
      <hr>
       </div>
      
      <div v-if="estudiante">
        <h2>Avanzado</h2>
        <DetalleVigencia v-if="puedeEditarVigencia" :estudiante="estudiante"></DetalleVigencia>

        <DetalleNivelesDisponibles  v-if="puedeEditarCurso" :estudiante="estudiante"></DetalleNivelesDisponibles>

        <DetalleNivelActual  v-if="puedeEditarCurso" :estudiante="estudiante"></DetalleNivelActual>

        <DetalleCursoActual  v-if="puedeEditarCurso" :estudiante="estudiante"></DetalleCursoActual>

        <DetalleMetodoPago  v-if="puedeEditarMetodoPago" :estudiante="estudiante"></DetalleMetodoPago>

        <DetalleSabados v-if="puedeEditarSabados" :estudiante="estudiante"></DetalleSabados>
        
        <DetalleVersant v-if="puedeEditarVersant" :estudiante="estudiante"></DetalleVersant>

        <DetalleSede v-if="permiso('F775D80')" :estudiante="estudiante"></DetalleSede>
        
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import DetalleActivarCurso from '@/components/Estudiantes/DetalleActivarCurso';
  import DetalleVigencia from '@/components/Estudiantes/DetalleVigencia';
  import DetalleCursoActual from '@/components/Estudiantes/DetalleCursoActual';
  import DetalleNivelActual from '@/components/Estudiantes/DetalleNivelActual';
  import DetalleNivelesDisponibles  from '@/components/Estudiantes/DetalleNivelesDisponibles';
  import DetalleMetodoPago from '@/components/Estudiantes/DetalleMetodoPago';
  import DetalleSede from '@/components/Estudiantes/DetalleSede';
  import DetalleSabados from '@/components/Estudiantes/DetalleSabados';
  import DetalleVersant from '@/components/Estudiantes/DetalleVersant';

  export default {
    name: 'EstudianteEditar',
    components: {
      DetalleActivarCurso,
      DetalleVigencia,
      DetalleNivelesDisponibles,
      DetalleCursoActual,
      DetalleNivelActual,
      DetalleMetodoPago,
      DetalleSede,
      DetalleSabados,
      DetalleVersant,
    },
    data () {
      return {
           estudiante_id: null,
      }
    },
    mounted () {
     this.estudiante_id = this.$route.params.id;
      if(!this.detalle[this.estudiante_id]){
        this.actualizar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'estudiantes/fetchDetalle',
        editar: 'estudiantes/editar',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      procesarCancelar(){
        this.fetchDetalle(this.estudiante_id).then(()=>{
          this.$router.push('/estudiante/detalle/'+this.estudiante_id)
        })
      },
      actualizar(){
        this.fetchDetalle(this.estudiante_id)
        .catch((response)=>{
          console.log('error al actualizar')
        })
      },
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          console.log('validateBeforeSubmit')
          if ( ! result) {
            console.log('validateBeforeSubmit error')
            //hay errores
          } else {
            console.log('validateBeforeSubmit no error')
            this.editar(this.estudiante).then(()=>{
                this.setWarning('Estudiante Actualizado', { root: true }).then(()=>{
                this.fetchDetalle(this.estudiante_id).then(()=>{
                  this.$router.push('/estudiante/detalle/'+this.estudiante_id)
                })
              })
            }).catch(error=>{
              //todo probar si se puede quitar
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        detalle: state => state.estudiantes.detalle, 
        warning: state => state.warning
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      estudiante(){
        return this.detalle[this.estudiante_id]
      },

      puedeEditarSabados(){
        if(this.estudiante.sabados == null){
          return this.permiso('C297CD9C') || this.permiso('7F91307F')
        }else{
          return this.permiso('C297CD9C')//PERMISO DE EDITAR
        }
      },

      //TODO:  ACTUALIZAR CODIGOS DE PERMISOS
      puedeEditarVersant(){
        if(this.estudiante.versant == null){
          return this.permiso('C297CD9C') || this.permiso('7F91307F')
        }else{
          return this.permiso('C297CD9C')//PERMISO DE EDITAR
        }
      },

      puedeEditarMetodoPago(){
        if(this.estudiante.tipo_pago == null){
          return this.permiso('EA5E1F0B') || this.permiso('700279E9')
        }else{
          return this.permiso('EA5E1F0B')//PERMISO DE EDITAR
        }
      },

      puedeEditarVigencia(){
        if(this.estudiante.vigencia == null){
          return this.permiso('1904624A') || this.permiso('20BAD775')
        }else{
          return this.permiso('1904624A')//PERMISO DE EDITAR
        }
      },

      puedeEditarCurso(){
        if(this.estudiante.curso_actual_id == null){
          return this.permiso('C448968B') || this.permiso('C0F0653E')
        }else{
          return this.permiso('C448968B')//PERMISO DE EDITAR
        }
      }
      
      
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>