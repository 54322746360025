<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_examennuevo"></b-navbar-toggle>
        <h1  class="text-center navbar-brand pt-0 pb-0 mb-0">Editar Banners</h1>
        <b-collapse is-nav id="nav_collapse_examennuevo">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <a :href="`#/banners/lista`" class="nav-link" title="Ver">Volver</a> 
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <br>
    <div class="container pt-3 m-auto text-left ">
         <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
      <div>
        <SelectCursoAll titulo="Curso" :modelo="banner.segmento.cursos" @change="banner.segmento.cursos=$event"/>  
        <SelectSedeAll titulo="Sede" :modelo="banner.segmento.sedes" @change="banner.segmento.sedes=$event"/>      
        <InputTextSimple titulo="Url" :modelo="banner.url" @change="banner.url=$event"/>
        <SelectTipoPago titulo="Tipo de Pago" :modelo="banner.segmento.metodoPago" @change="banner.segmento.metodoPago=$event"/>
        
        <div class="form-group row">
            <label class="col-3 col-form-label">Fecha implementación:</label>
            <div class="col-4">
              <date-picker  class="date-picker" lang="es" v-model="banner.fecha_inicio" type="date" 
            :time-picker-options="timePickerOptions" :clearable="true"></date-picker>
            </div>
            <div class="col-4">
             <date-picker  class="date-picker" lang="es" v-model="banner.fecha_fin" type="date" 
            :time-picker-options="timePickerOptions" :clearable="true"></date-picker>
            </div>
        </div>   

      </div>  

      <button class="btn btn-block btn-primary">Guardar</button>
      </form>
      
    </div>

  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import SelectSedeAll from '@/components/Forms/SelectSedeAll'
  import SelectCursoAll from '@/components/Forms/SelectCursoAll'
  import SelectTipoPago from '@/components/Forms/SelectTipoPago'
  import DatePicker from 'vue2-datepicker'

  export default {
    name: 'BannersNuevo',
    components: {
      DatePicker,
      SelectSedeAll,
      SelectCursoAll,
      SelectTipoPago

    },
    data () {
      return {
      banner_id: null,
      }
    },
    mounted () {
            
        this.banner_id = this.$route.params.id;
        console.log(this.banner_id)

        this.fetchDetalleBanners(this.banner_id).then((data)=>{console.log(data);console.log('anyeliz')
        
        this.banner = data;
        
        })
      if(!this.cursos){
            this.fetchCursos()
      }       
           this.fetchMetodosPago()
           this.fetchLista()  
    },
    
    methods:{
      ...mapActions({
        fetchCursos: 'asw/fetchCursos',
        fetchMetodosPago: 'asw/fetchMetodosPago',
        fetchLista: 'sedes/fetchLista',
        postGuardar: 'banner/postGuardar',
        fetchDetalleBanners: 'banner/fetchDetalleBanners',
        editar: 'banner/editar',
        setWarning: 'setWarning'
      }),
 ...mapMutations({

      }),
     
     validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          console.log('validateBeforeSubmit')
          if ( ! result) {
            console.log('validateBeforeSubmit error')
            //hay errores
          } else {
            console.log('validateBeforeSubmit no error')
            this.editar(this.banner).then(()=>{
                this.setWarning('Banner Actualizado', { root: true }).then(()=>{
                this.fetchDetalleBanners(this.banner_id).then(()=>{
                  this.$router.push('/banners/detalle/'+this.banner_id)
                })
              })
            }).catch(error=>{
              //todo probar si se puede quitar
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        cursos:  state=> state.asw.cursos,
        metodos_pago : state => state.asw.metodos_pago,
        sedes: state => state.sedes.lista,
        warning: state => state.warning,
        user: state => state.auth.user, //
        detalleBanner: state => state.banner.detalleBanner, 


      }),
      banner(){
        
        return this.detalleBanner[this.banner_id]
        

      },

      
    }
    
  }
</script>