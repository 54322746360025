<template>
  <div class="container">

    <div class="agendamiento_fila row" v-if="agendamiento">
        <div class="col-md-3">
          <strong class="cap">{{textoTipoSolicitud}} / {{textoTipoClase}}</strong><br>
          <span>{{textoCurso}}</span><br>
          <strong>{{textoTema}}</strong><br>
          <span>{{textoSede}} - {{textoFechaClase}}</span>
          <span>{{textoSalon}}</span><br>
          <!--
            <button v-if="permiso('F04A9590') && puedeEliminarTema" @click="procesarEliminarTema" class="btn btn-danger btn-sm" >Liberar Tema</button>
            <button v-if="permiso('36225995') && puedeAsignarTemaAuto" @click="procesarAsignarTemaAuto" class="btn btn-danger btn-sm" >Calcular Tema</button>
          -->
        </div>
        <div class="col-md-3">
          <strong class="cap">{{textoEstado}}</strong><br>
          <span>{{textoAsistenciaFecha}}</span><br>
          <button v-if="permiso('22D98724') && puedeCancelar" @click="cancelando=true" class="btn btn-danger btn-sm" >Cancelar Agendamiento</button>

          <!--
            <button v-if="permiso('F3C6F430') && puedeAnularFirma" @click="procesarAnularFirma" class="btn btn-danger btn-sm" >Anular Asistencia</button>
          -->
        </div>
        <div class="col-md-6">
          <p v-for="observacion in observaciones">
            {{ observacion.texto}}<span>  {{ observacion.usuario}} / {{ observacion.fecha}} </span><br>
          </p>
        </div>
        
       

      <!--
      <div class="col-md-5 observaciones">
          <DetalleAgendamientoObservaciones @addNota="addNota"  :row="agendamiento" />  
      </div>
    -->
    </div>
    
    <b-modal @ok="procesarCancelar" @cancel="motivoCancelado=''" v-model="cancelando"  id="modalCancelando"
    :ok-disabled="motivoCancelado.length<motivoCanceladoMin"  title="Motivo de la cancelación">
      <div class="mb-2">
        <b-form-textarea
          id="textarea"
          v-model="motivoCancelado"
          placeholder="Cuéntanos, por qué es necesario hacer esta cancelación manual?"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <pre class="d-block text-right">{{ motivoCancelado.length }}/{{ motivoCanceladoMin }}</pre>
      </div>
    </b-modal>

  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import DetalleAgendamientoObservaciones from '@/components/Estudiantes/DetalleAgendamientoObservaciones';

  export default {
    name: 'DetalleAgendamiento',
    components: {
      DetalleAgendamientoObservaciones
    },
    props:{
      agendamiento: Object,
      estudiante: Object
    },
    data () {
      return {
        tema_id_local : null,
        texto_cambio_tipo : null,
        cambiando_tema : false,
        texto_cambio_tipo: null,
        cancelando: false,
        motivoCancelado: '',
        motivoCanceladoMin: 40
      }
    },
    
    mounted () {
      if(this.sedes.length==0){
        this.fetchSedes()
      }
    },
    methods:{
      ...mapActions({
        setTema: 'agendamientos/setTema',
        eliminarTema: 'agendamientos/eliminarTema',
        asignarTemaAuto: 'agendamientos/asignarTemaAuto',
        anularFirma: 'agendamientos/anularFirma',
        fetchCurso: 'cursos/fetchDetalle',
        fetchAgendamientos: 'estudiantes/fetchAgendamientos',
        cancelar: 'programacion/cancelar',
        fetchSedes: 'sedes/fetchLista',
      }),
      ...mapMutations({

      }),
      recargar(){
        
      },
      iniciarCambiarTema(){
        this.cambiando_tema=true
        let curso = this.getCurso(this.estudiante.curso_actual_id)
        if(!curso || !curso.temas){
          this.fetchCurso(this.estudiante.curso_actual_id)
        }
      },
      addNota(datos){
        //this.saveObservacion({agendamiento_id:datos.id,texto:datos.texto}).catch(()=>{})
      },
      procesarCancelar(){
        let payload = {
          agendamiento_id:this.agendamiento.id,
          motivo:this.motivoCancelado
        }
        this.cancelar(payload).then(()=>{
          this.fetchAgendamientos(this.estudiante.id).then(()=>{})
        })
      },
      procesarAnularFirma(){
        this.anularFirma({agendamiento_id:this.agendamiento.id}).then(()=>{
          this.fetchAgendamientos(this.estudiante.id).then(()=>{})
        })
      },
      procesarTema(){
        this.setTema({agendamiento_id:this.agendamiento.id,tema_id:this.tema_id_local.id}).then(()=>{
          this.fetchAgendamientos(this.estudiante.id).then(()=>{
            this.cambiando_tema=false
          })
        })
      },
      procesarAsignarTemaAuto(){
        this.asignarTemaAuto({agendamiento_id:this.agendamiento.id}).then(()=>{
          this.fetchAgendamientos(this.estudiante.id).then(()=>{})
        })
      },
      procesarEliminarTema(){
        this.eliminarTema({agendamiento_id:this.agendamiento.id}).then(()=>{
          this.fetchAgendamientos(this.estudiante.id).then(()=>{
            this.cambiando_tema=false
          })
        })
      }

    },
    computed: {
      ...mapState({
        cursos: state => state.clases.cursos, 
        sedes: state  => state.sedes.lista,
      }),
      ...mapGetters({
        getCurso: 'cursos/getCursoDetalle', 
        permiso: 'auth/permiso', 
        getSede: 'sedes/getSede',
      }),
      temaIdLocal:{
        get () {
          return this.tema_id_local
        },
        set (value) {
          this.tema_id_local = value
          if(this.tema_id_local.tipo_clase != this.agendamiento.tipo_clase){
            this.texto_cambio_tipo = "El estudiante deberá cambiar de clase"
          }else{
            this.texto_cambio_tipo = null
          }
        }
      },
      puedeCancelar(){
        if(this.agendamiento){
          if( this.agendamiento.estado=='solicitado' || this.agendamiento.estado=='asignado'){
             return true;              
          }
        }
        return false
      },
      puedeAnularFirma(){
        if(this.agendamiento){
          if( this.agendamiento.estado=='asistido' || this.agendamiento.estado=='ausencia'){
             return true;              
          }
        }
        return false
      },
      puedeEliminarTema(){
        if(this.agendamiento){
          if(this.agendamiento.tema_id){
            if( this.agendamiento.estado=='solicitado' || this.agendamiento.estado=='asignado'){
              return true;              
            }
             
          }
        }
        return false
      },
      puedeAsignarTemaAuto(){
        if(this.agendamiento){
          if(!this.agendamiento.tema_id){
            if( this.agendamiento.estado=='solicitado' || this.agendamiento.estado=='asignado'){
             return true;              
            }
          }
        }
        return false
      },
      puedeRestablecerTema(){
        if(this.agendamiento && this.agendamiento.tema_id){
          return true;              
        }
        return false
      },

      curso(){
        if(this.estudiante){
          let curso_id = this.estudiante.curso_actual_id
          let curso = this.getCurso(curso_id)
          return curso  
        }
        return null
      },

      textoSalon(){
        if(this.agendamiento && this.agendamiento.salon){
          let salon = JSON.parse(this.agendamiento.salon)
          let texto = (salon.sede +' / '+salon.nombre)
          return texto
        }
        return ''
      },
      textoSede(){
        if(this.agendamiento && this.agendamiento.sede_id){
          let texto = this.sedeActual ? this.sedeActual.nombre : "";
          return texto
        }
        return ''
      },
      textoTipoClase(){
        if(this.agendamiento ){
          let texto = this.agendamiento.tipo_clase
          return texto
        }
        return ''
      },
      textoTipoSolicitud(){
        if(this.agendamiento ){
          let texto = this.agendamiento.tipo_solicitud
          return texto
        }
        return ''
      },
      textoTema(){
        if(this.agendamiento && this.agendamiento.tema){
          let texto = this.agendamiento.tema.nombre
          return texto
        }
        return ''
      },
      textoCurso(){
        if(this.agendamiento.tema && this.agendamiento.tema.curso && this.agendamiento.tema.curso.nombre){
          let texto = this.agendamiento.tema.curso.nombre
          return texto
        }
        return ''
      },
      observaciones(){
        if(this.agendamiento && this.agendamiento.observaciones){
          return  JSON.parse(this.agendamiento.observaciones)
        }
        return null
      },
      textoFechaClase(){
        if(this.agendamiento){
          let clase_fecha = new Date(this.agendamiento.fecha+'T'+this.agendamiento.hora).toLocaleString()
          return clase_fecha
        }
        return ''
      },
      textoAsistenciaFecha(){
        if(this.agendamiento && this.agendamiento.asistencia_fecha){
          let asistencia_fecha = new Date(this.agendamiento.asistencia_fecha).toLocaleString()
          return asistencia_fecha
        }
        return ''
      },
      
      textoEstado(){
        if(this.agendamiento){
          if( this.agendamiento.estado=='solicitado'){
            return 'Sin Asignar'
          }else{
            return this.agendamiento.estado  
          }
          
        }
        return ''
      },
      textoConformidadFecha(){
        if(this.agendamiento && this.agendamiento.conformidad_fecha){
          let conformidad_fecha = new Date(this.agendamiento.conformidad_fecha).toLocaleString()
          return conformidad_fecha
        }
        return 'Pendiente'
      },

      sedeActual(){
        return this.getSede(this.agendamiento.sede_id)
      },
      
    }
  }
</script>

<style scope>
.agendamiento_fila {
    border-top: 1px solid #bbb;
    font-size:14px;
    line-height: 14px;
    padding-top: 10px !important;
}
.agendamiento_fila  strong{}
.agendamiento_fila  span{
  font-size: 10px;
  line-height: 10px;
  color:#888;
  font-style: oblique;
}
.agendamiento_fila  p{
      margin-bottom: 5px !important;
}
.agendamiento_fila  button{
      margin: 5px !important;
}
.cambiar-tema{
  padding: 10px;
    border: 1px solid grey;
}
.cap{
text-transform: capitalize;  
}
</style>