<template>
    <div>
      <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0 bg-blue">
          <b-navbar-toggle target="nav_collapse_actions"></b-navbar-toggle>
          <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Avance de Nivel</h1>
          <b-collapse is-nav id="nav_collapse_actions" >
            <b-navbar-nav class="ml-auto">
              <date-picker  class="date-picker" v-model="intervalo" lang="es" type="date" 
              :time-picker-options="timePickerOptions" range confirm :clearable="true"></date-picker>
            </b-navbar-nav>
            <b-navbar-nav  class="ml-auto">

              <b-nav-item  href="#" @click="generarReporte">Consultar</b-nav-item>
              
            </b-navbar-nav>
          </b-collapse>
      </b-navbar>
      <div class="container-fluid" >
        <div class="reporte_dia table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Nombres del estudiante</th>
                <th scope="col">Apellidos del estudiante</th>
                <th scope="col">Tipo de documento</th>
                <th scope="col">Número de documento</th>
                <th scope="col">Número de celular</th>
                <th scope="col">Correo electrónico</th>
                <th scope="col">Idioma</th>
                <th scope="col">Nivel actual</th>
                <th scope="col">Resultado</th>
                <th scope="col">Nota IFE</th>
                <th scope="col">Nota final módulo</th>
                <th scope="col">Módulos adquiridos</th>
                <th scope="col">Folio</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in registros">
                <td>{{item.estudiante.primer_nombre}} {{item.estudiante.segundo_nombre}}</td>
                <td>{{item.estudiante.primer_apellido}} {{item.estudiante.segundo_apellido}}</td>
                <td>{{item.estudiante.tipo_documento}}</td>
                <td>{{item.estudiante.numero_documento}}</td>
                <td>{{item.estudiante.movil}}</td>
                <td>{{item.estudiante.email}}</td>
                <td>{{getIdioma(item)}}</td>
                <td>{{item.cursoAnterior ? item.cursoAnterior.nombre : ''}}</td>
                <td>{{item.resultado}}</td>
                <td>{{item.nota_ife}}</td>
                <td>{{item.nota}}</td>
                <td>{{item.estudiante.modulos_adquiridos}}</td>
                <td>{{item.folio}}</td>
                <td>
                  <b-button size="sm" variant="link" @click="consultarNotas(item)">
                    <b-icon icon="exclamation-circle-fill" variant="info"></b-icon>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <b-modal size="lg" @ok="procesarNotas"  @cancel="cancelar" v-model="showNotas"  id="modalNotas"
      :ok-disabled="!puedeProcesarNotas"  :title="estudiante && estudiante.estudiante ? estudiante.estudiante.primer_nombre+' '+estudiante.estudiante.segundo_nombre+' '+estudiante.estudiante.primer_apellido+' '+estudiante.estudiante.segundo_apellido : ''">

      <div class="mb-2" >

        <div >
          <div v-if="estudiante && estudiante.estudiante">
            Correo: {{ estudiante.estudiante.email }} <br>
            Usuario MEL: {{ estudiante.estudiante.usuario_plataforma }} <br>
            Contraseña MEL: {{ estudiante.estudiante.usuario_plataforma }}
          </div>
          <b-table-simple hover small caption-top responsive>
            <b-thead head-variant="light"> <!---->
              <b-th>Tema</b-th>
              <b-th>Nota</b-th>
              <b-th>Valor</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="tema in temas" :key="tema.id">
                <b-td>{{tema.nombre}}</b-td>
                <b-td>
                  <!-- <b-form-spinbutton
                  id="sb-locale"
                  v-model="tema.nota"
                  :locale="locale"
                  min="0"
                  :max="tema.rango_nota"
                  step="0.01">
                </b-form-spinbutton> -->
                <input type="number"
                v-model="tema.nota"
                :max="tema.rango_nota"
                step="0.01"
                @keyup="maxValue(tema)"
                :disabled="'autonomo' != tema.tipo"
                class="form-control">
                </b-td>
                <b-td>{{((((tema.nota / tema.rango_nota) * tema.peso_nota)*5)/100).toFixed(3) }}</b-td>
              </b-tr>
              <b-tr>
                <b-td colspan="2">NOTA FINAL</b-td>
                <b-td>{{ notaFinal }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>

    </b-modal>

    
    <transition name="fade">
      <div class="alert alert-warning mt-2" role="alert" v-if="mensaje">{{mensaje}}</div>
    </transition>
        
    </div>
  </template>
  
  <script>
    import {mapState, mapActions, mapMutations} from 'vuex';
    import Vue from 'vue';
    import DatePicker from 'vue2-datepicker'
  
    export default {
      name: 'ReporteSolicitudAvance',
      components: {
        DatePicker
      },
      data () {
        return {
            intervalo:[],
            registros: [],
            temas:[],
            timePickerOptions:{    
            },
            showNotas:false,
            locale:'es-CO',
            mensaje:null,
            estudiante:null
        }
      },
      mounted () {
        
      },
      methods:{
        ...mapActions({
          fetchNotas: 'progresos/fetchNotas',
          actualizarNotas: 'progresos/actualizarNotas'
        }),
        ...mapMutations({
          startProcessing: 'startProcessing',
          stopProcessing: 'stopProcessing'
        }),
        
        generarReporte(){
          console.log('Generando reporte....')
          this.startProcessing()
          Vue.http.post('reportes/solicitud-avance',{
            fecha_inicio:this.$moment(this.intervalo[0]).format('YYYY-MM-DD'),
            fecha_fin:this.$moment(this.intervalo[1]).format('YYYY-MM-DD')
          }).then(
            response =>{
              this.registros = response.data;
            }
          ).catch(error=>{
            console.log(error)
          }).finally(()=>{
            this.stopProcessing()
          })
        },
        presentDate(value){
          if (value) {
            return this.$moment(value).format('DD/MM/YYYY')
          }
          return '';
        },
        getIdioma(item) {
          if (item.cursoAnterior) {
            return item.cursoAnterior ? (item.cursoAnterior.codigo.indexOf('EN') > -1 ? 'Inglés' : 'Frances') : '';
          } else if (item.cursoNuevo) {
            return item.cursoNuevo ? (item.cursoNuevo.codigo.indexOf('EN') > -1 ? 'Inglés' : 'Frances') : '';
          }
          return '';
        }, 
        consultarNotas(item) {
          this.estudiante = item;
          this.fetchNotas({estudiante_id:item.estudiante_id}).then((result) => {
            this.showNotas = true;
            this.temas = result;
          }).catch((error) => {
            console.error(error)
          })
        },
        procesarNotas() {
          var data = {estudiante_id:this.estudiante.estudiante_id,temas:this.temas}
          this.actualizarNotas(data).then((data) => {
            this.mensaje = data.mensaje;
            this.generarReporte();
            setTimeout(() => {this.mensaje = null},3000)

          }).catch((error) => {
            console.error(error)
          })
        },
        cancelar() {
          console.log("cancelar");
        },
        maxValue(tema) {
          if (tema.nota > tema.rango_nota) {
            tema.nota = tema.rango_nota;
          }
        }
      },
      computed: {
        ...mapState({
         
        }),
        notaFinal(){
          if(this.temas && this.temas){
            let temasConNotas = this.temas.filter(element=>{
              return element.rango_nota && element.peso_nota
            })
            let notaF = 0;
            for (let i in temasConNotas){
              let tema = temasConNotas[i]
              let notaParcial = (tema.nota / tema.rango_nota) * tema.peso_nota
              notaParcial = notaParcial.toPrecision(3)*1
              notaParcial = notaParcial*5/100
              notaF += notaParcial
              
            }

            return notaF.toPrecision(3);	
          }
          
          return null
        },
      
        puedeProcesarNotas(){
          if(this.temas.filter(x => x.nota && x.tipo == 'autonomo').length == this.temas.filter(x => x.tipo == 'autonomo').length) {
            return true;
          }
          return false
        },

      }
    }
  </script>
  
  <style scope>
  
  .date-picker{
    
  }
  </style>